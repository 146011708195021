@keyframes load {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader {
  border: 0.2em solid #212529;
  border-top: 0.2em solid transparent;
  border-radius: 100%;
  width: 1rem;
  height: 1rem;
  animation: load 0.75s linear infinite;
}
