.review-sheet-warn-modal {
  .modal-body {
    padding: 16px;
  }
  .modal-footer {
    width: 100%;
    margin: 0;
    border-top: 1px solid #dee2e6;
    .footer-content {
      display: flex;
      gap: 16px;
      width: 100%;
      justify-content: end;
      button {
        max-width: 150px;
        font-size: 14px;
      }
      .no-button {
        background-color: #2cda9b;
        color: #fff;
        border: none;
        transition: 0.5s;
        width: 100%;
        &:hover {
          background-color: #25bb84;
          color: #fff;
          transition: 0.5s;
        }
        &:focus {
          box-shadow: none;
        }
        &:disabled {
          opacity: 0.65;
        }
      }
      .yes-button {
        background-color: transparent;
        color: #5e677b;
        border: 1px solid #5e677b;
        width: 100%;
        &:hover {
          border: 1px solid #dc3545;
          color: #dc3545;
        }
        &:focus {
          box-shadow: none;
        }
      }
    }
  }
}
