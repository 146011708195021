.keyword-card-container {
  position: relative;
  display: flex;
  align-items: start;
  justify-content: left;
  gap: 12px;
  height: fit-content;
  padding: 6px 0 6px 15px;
  transition: all 0.2s;
  cursor: pointer;
  font-size: 12px;

  &:hover,
  &.selected {
    background-color: #e7f0fa;

    p.time {
      color: #3478f6;
      background-color: unset;
    }
  }

  p {
    margin: 0;
  }

  p.time {
    top: 4px;
    position: relative;
    z-index: 2;
    min-width: 42px;

    color: #999999;

    transition: all 0.2s;
    background-color: white;
  }

  div.issueCard {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 3px 6px;
    border-radius: 5px;

    img {
      width: 18px;
    }
  }

  div.keyCard {
    background: rgb(193 181 253 / 20%);
    color: black;
  }

  .time-line {
    position: absolute;
    background-color: #e7f0fa;
    width: 3px;
    height: 100%;
    left: 34.5px;
    top: -12px;
  }
}
