.aside-main {
  display: flex;
  height: calc(100vh - 56px);
}

#main {
  width: 100%;
  margin-left: 0px;
}

@media (min-width: 1400px) {
  #main > .container,
  #main > .container-lg,
  #main > .container-md,
  #main > .container-sm,
  #main > .container-xl,
  #main > .container-xxl {
    max-width: 1600px;
  }
}
