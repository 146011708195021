.issues-tab-container {
  .issues-tab-papers {
    display: flex;
    width: 100%;
    gap: 16px;
    .issues-tab-list-container {
      width: 35%;
      max-height: 70vh;
    }
    .issues-tab-form-container {
      width: 65%;
      min-height: 50vh;
      .paper-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 16px;
      }
      .issues-tab-form-content {
        max-height: 63vh;
        min-height: 50vh;
        overflow: auto;
        padding: 16px;
        height: 100%;
        .issues-tab-form-content-buttons {
          display: flex;
          justify-content: end;
          gap: 16px;
          width: 95%;
          margin-top: 16px;
          .inputs-cancel-button {
            background-color: transparent;
            color: #5e677b;
            border: 1px solid #5e677b;
            width: 100%;
            max-width: 150px;
            &:hover {
              border: 1px solid #dc3545;
              color: #dc3545;
            }
            &:focus {
              box-shadow: none;
            }
          }
          .inputs-submit-button {
            background: #2cda9b;
            border: none;
            width: 100%;
            max-width: 150px;
            &:hover {
              background-color: #27c289;
            }
            &:focus {
              box-shadow: none;
            }
          }
        }
      }
    }
  }
}

.dropdown-toggle {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white !important;
  color: #444 !important;
  border-color: #aaa;
  overflow: hidden;
  box-shadow: none;
}
