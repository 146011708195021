.alert-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  min-height: 40px;
  margin-bottom: 8px;
  width: 100%;
  padding: 8px 24px;
  font-weight: normal;

  &.primary {
    color: #004085;
    background: #cce5ff;
    border-color: #b8daff;
  }

  &.secondary {
    color: #383d41;
    background: #e2e3e5;
    border-color: #d6d8db;
  }

  &.success {
    color: #155724;
    background: #d4edda;
    border-color: #c3e6cb;
  }

  &.danger {
    color: #721c24;
    background: #f8d7da;
    border-color: #f5c6cb;
  }

  &.warning {
    color: #856404;
    background: #fff3cd;
    border-color: #ffee;
  }

  &.info {
    color: #0c5460;
    background: #d1ecf1;
    border-color: #bee5eb;
  }

  &.light {
    color: #818182;
    background: #fefefe;
    border-color: #fdfdfe;
  }

  &.dark {
    color: #1b1e21;
    background: #d6d8d9;
    border-color: #c6c8ca;
  }

  .alert-content {
    display: flex;
    align-items: center;
    width: 100%;
    svg {
      margin-right: 8px;
    }
    .alert-text {
      margin: 0;
      max-width: 90%;
    }
  }
  .alert-button {
    font-weight: bold;
    cursor: pointer;
  }
}
