.paperReview {
  width: 100%;

  .contentPreview {
    padding: 20px;

    body {
      background: none;
    }
  }
}

.reviewContent {
  padding: 20px;
}

.templateContainer {
  margin: 40px;
  gap: 20px;
  display: flex;
  flex-wrap: wrap;

  button:focus {
    border-color: unset !important;
    box-shadow: unset !important;
  }

  .template {
    width: 138px;
    height: 138px;
    border-radius: 5px;
    border: 1px solid #dcdcdc;
    background: #f2f4f7;

    svg {
      width: 48px;
      height: 48px;
      margin-top: 20px;
      color: #dcdcdc !important;

      &.selected {
        color: #3478f6 !important;
      }
    }

    p {
      padding-top: 10px;
      color: #5e677b;
      font-size: 12px;
      line-height: 14.4px;

      &.selected {
        color: #3478f6 !important;
      }
    }
  }
}

.informationContainer {
  margin: 40px;

  .inputContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    span {
      font-weight: 600;
      margin-bottom: 5px;
    }

    input {
      border-radius: 4px;
      border: 1px solid;
      border-color: #cdcdcd;
      padding: 10px;
      color: #333;
    }
  }
}

.rteContainer {
  margin: 40px;

  .subject-input-container {
    display: flex;
    margin-top: 16px;
    align-items: center;
    gap: 16px;
    margin-bottom: 32px;
    input {
      width: 100%;
      border: 1px solid #dadada;
      border-radius: 6px;
      padding: 9px 16px;
    }
  }
  .rteActions {
    button {
      border: none;
      margin: 20px 0;
    }
  }
}

.actionsContainer {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid;
  border-color: #eaeaf0;
  margin: 0;
  padding: 20px;
  position: sticky;
  bottom: 0;
  left: 0;
  background: #fff;
  border-radius: 0 0 4px 4px;
  .leftContentContainer {
    .leftContent {
      display: flex;
      align-items: center;
      button {
        border: 1px solid #dcdcdc;
        background: none;
        padding: 5px;
      }
      .copyButton {
        border-radius: 4px 0 0 4px;
      }
      svg {
        height: 24px;
        width: 24px;
      }
    }
  }
  .rightContent {
    gap: 10px;
    display: flex;
    align-items: center;

    * {
      min-width: 100px;
    }

    .previousButton {
      color: #333;
      background-color: #eaeaf0 !important;
      border-color: #eaeaf0 !important;
      &:disabled {
        color: #aaa;
      }
      &:focus {
        box-shadow: none;
      }
    }
    .nextButton {
      background-color: #2bd999 !important;
      border-color: #2cda9b !important;
      &:focus {
        box-shadow: none;
      }
    }
  }
}

.bodyContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 120px;
  justify-content: center;

  svg {
    // color: #2cda9b;
    width: 48px;
    height: 48px;
  }

  &.success svg {
    color: #2cda9b;
  }
  &.fail svg {
    color: #ff5216;
  }

  p {
    color: #000000;
  }
}

.customModal {
  .modal-content {
    min-width: 320px;
  }

  .modal-footer {
    align-self: center;
    justify-content: center;
    width: 100%;

    .footerContainer {
      display: flex;
      gap: 20px;
    }

    button {
      font-size: 14px;
    }

    .backButton {
      background-color: #dcdcdc;
      color: #333;
      border: none;
    }
  }
}

.attachment-warn-modal {
  .modal-body {
    padding: 16px;
  }
  .modal-footer {
    width: 100%;
    margin: 0;
    border-top: 1px solid #dee2e6;
    .footer-content {
      display: flex;
      gap: 16px;
      width: 100%;
      justify-content: end;
      button {
        max-width: 150px;
        font-size: 14px;
      }
      .dont-send {
        background-color: #2cda9b;
        color: #fff;
        border: none;
        transition: 0.5s;
        width: 100%;
        &:hover {
          background-color: #25bb84;
          color: #fff;
          transition: 0.5s;
        }
        &:focus {
          box-shadow: none;
        }
        &:disabled {
          opacity: 0.65;
        }
      }
      .send {
        background-color: transparent;
        color: #5e677b;
        border: 1px solid #5e677b;
        width: 100%;
        &:hover {
          border: 1px solid #dc3545;
          color: #dc3545;
        }
        &:focus {
          box-shadow: none;
        }
      }
    }
  }
}

.jodit-ui-group:empty {
  display: none;
}

.input-error {
  border-color: red !important;

  &:focus,
  &:focus-visible {
    border-color: red !important;
    box-shadow: unset !important;
    outline: none;
  }
}

.error-message {
  color: red;
  font-size: 14px;
}

.attach-files-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  .upload-drop-zone {
    display: flex;
    flex-direction: column;
    font-weight: normal;
    color: #5e677b;
    border: 1px dashed #5e677b;
    padding: 24px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    .upload-drop-zone-text {
      display: flex;
      flex-direction: column;
      align-items: center;
      .upload-choose-file-button {
        color: #004fff;
        text-decoration: underline;
        cursor: pointer;
      }
      .upload-choose-file-input {
        display: none;
      }
      .upload-drop-zone-max-size {
        svg {
          margin-right: 4px;
        }
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
  .files-upload-cards {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    .files-upload-card {
      display: flex;
      border: 1px solid #dcdcdc;
      border-radius: 4px;
      flex-direction: column;
      padding: 8px;
      width: 320px;
      .files-upload-card-body {
        display: flex;
        gap: 8px;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .files-upload-card-content {
          display: flex;
          flex-direction: column;
          width: 80%;
          .files-upload-card-title {
            color: #444;
            font-size: 14px;
            font-weight: 600;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .files-upload-card-infos {
            display: flex;
            gap: 4px;
            align-items: center;
            .files-upload-card-info {
              display: flex;
              align-items: center;
              gap: 4px;
              color: #5e677b;
              font-size: 12px;
            }
            .files-upload-card-error {
              display: flex;
              align-items: center;
              color: #dc3545;
              font-size: 12px;
            }
            .files-upload-card-completed {
              display: flex;
              align-items: center;
              color: #28a745;
              font-size: 12px;
            }
          }
        }
        .files-upload-card-actions {
          display: flex;
          gap: 8px;
          align-items: center;
          svg {
            cursor: pointer;
          }
        }
      }
      .progress-bar-track {
        width: 100%;
        background-color: #dcdcdc;
        height: 4px;
        border-radius: 2px;
        .progress-bar {
          height: 100%;
          background-color: #2cda9b;
          border-radius: 2px;
        }
      }
    }
  }
}
