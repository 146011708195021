.rule-violation-card-container {
  position: relative;
  display: flex;
  align-items: start;
  justify-content: left;
  gap: 12px;
  height: fit-content;
  padding: 6px 0 6px 15px;
  transition: all 0.2s;
  cursor: pointer;
  font-size: 12px;

  &:hover,
  &.selected {
    background-color: #e7f0fa;

    p.time {
      color: #3478f6;
      background-color: unset;
    }
  }

  p {
    margin: 0;
  }

  p.time {
    top: 4px;
    position: relative;
    z-index: 2;
    min-width: 42px;

    color: #999999;

    transition: all 0.2s;
    background-color: white;
  }

  div.keyCard {
    background: rgb(193 181 253 / 20%);
    color: black;
  }

  .issueCardContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .card-tag-container {
      display: flex;
      align-items: center;

      .violation-tags-container {
        display: flex;
      }

      div.issueCard {
        display: flex;
        align-items: center;
        gap: 4px;
        padding: 3px 6px;
        border-radius: 5px;
        max-height: 24px;

        img {
          width: 18px;
        }
      }
    }

    .issueExplanation {
      margin: 0 10px;
      width: 100%;
    }

    div.actionsCard {
      justify-content: end;
      display: flex;
      gap: 10px;
      padding-right: 10px;
      font-size: 16px;
    }
  }

  .time-line {
    position: absolute;
    background-color: #e7f0fa;
    width: 3px;
    height: 100%;
    left: 34.5px;
    top: -12px;
  }
}
