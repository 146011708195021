.issue-modal-container {
  .modal-header {
    padding: 16px 16px 16px 32px;
    .issue-modal-title {
      font-size: large;
    }
  }
  .modal-body {
    max-height: 550px;
    overflow: auto;
    padding: 32px;
    border-bottom: 1px solid #dee2e6;
    .issue-modal-rule-violation-cards {
      .issue-modal-rule-violation-card {
        margin-bottom: 32px;
        .violation-rule {
          display: flex;
          align-items: center;
          gap: 4px;
          padding: 3px 6px;
          border-radius: 5px;
          max-height: 24px;
          background: rgba(236, 126, 25, 0.2);
          color: red;
          width: fit-content;
          font-size: 14px;
        }
      }
    }
  }
  .modal-footer {
    .issue-modal-button {
      width: 130px;
      height: 32px;
      align-items: center;
      justify-content: center;
      display: flex;
      border: none;
      border-radius: 4px;
      &.confirm {
        background-color: #2bd999;
        color: #fff;
        &:hover {
          background-color: #27c58b;
        }
      }
      &.cancel {
        color: #dc3545;
        background-color: transparent;
        border: 1px solid #dc3545;
        &:hover {
          color: #fff;
          background-color: #c12e3d;
        }
      }
    }
  }
}
