.paperIssues {
  height: 100%;
}

.issueData {
  width: 100%;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  font-size: 12px !important;
  flex: 1;
  .tabIssuesLabel {
    font-size: 12px !important;
  }

  display: flex;
  flex-direction: column;

  .css-1ujnqem-MuiTabs-root {
    overflow: hidden;
  }

  .filter {
    width: 100%;
    display: flex;
    .search-input {
      display: flex;
      align-items: center;
      border-top: 1px solid #dcdcdc;
      border-bottom: 1px solid #dcdcdc;
      padding-left: 16px;
      width: 65%;

      label {
        display: flex;
        height: 20px;
      }

      svg {
        width: 20px;
        height: 20px;
        color: #999999;
        stroke-width: 1px;
      }

      .plus {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        border-left: 1px solid #dcdcdc;
        height: 40px;
      }

      input {
        width: 100%;
        border: none;
        padding: 8px;
        outline: none;
      }
    }

    .rejected-filter {
      align-items: center;
      border-top: 1px solid #dcdcdc;
      border-bottom: 1px solid #dcdcdc;
      border-left: 1px solid #dcdcdc;
      width: 35%;
      font-weight: 700;
      color: #5e677b;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      padding: 8px;
      box-sizing: content-box;

      .eye-icon {
        margin-right: 4px;
        font-size: 60px;
      }
    }
  }
}

.contentIssueTabsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .contentIssueTabs {
    button {
      padding: 0 12px;
      height: 40px !important;
      min-height: 40px !important;
      min-width: 60px !important;
    }

    height: 40px !important;
    min-height: 40px !important;
    min-width: 40px !important;
  }
  .new-rv-container {
    display: flex;
    align-items: center;
    gap: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #2cda9b;
    border-radius: 4px;
    padding: 0px 8px;
    height: 30px;
    cursor: pointer;
    margin-right: 8px;
    .new-rv-text {
      font-size: 12px;
      color: #2cda9b;
    }
    &:hover {
      border: none;
      background: #2cda9b;
      svg {
        fill: #fff;
      }
      .new-rv-text {
        color: #fff;
      }
    }
  }
}

.issueParagraphDiv {
  flex: 1;
}

.thumbUp,
.thumbDown,
.edit {
  cursor: pointer;
}

.thumbUp {
  &:hover {
    svg {
      fill: #2cda9b;
    }
  }
}

.thumbDown {
  &:hover {
    svg {
      fill: #ff5216;
    }
  }
}

.edit {
  &:hover {
    svg {
      fill: #469fe9;
    }
  }
}

.thumb-up-selected {
  color: #2cda9b;
}

.thumb-down-selected {
  color: #ff5216;
}

.ruleViolationDiv {
  width: 100%;
  position: relative;
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  padding-right: 16px;

  div.ruleViolationCard {
    background: rgba(236, 126, 25, 0.2);
    color: red;
    width: fit-content;

    p {
      white-space: nowrap;
    }
  }

  p.issueExplanation {
    color: #444;
  }

  div.buttons {
    display: flex;
    gap: 10px;
    position: absolute;
    z-index: 3;

    margin: 0;
    right: 16px;
    top: 4px;

    svg {
      width: 24px;
      height: 24px;
      color: rgba(161, 172, 178, 1);
      &.edit {
        color: rgba(194, 203, 210, 1);
      }
    }

    button {
      margin: 0;
      padding: 0;
      background: none;
      border: none;
    }
  }
}

.issueParagraphDiv > :first-child {
  .progressBar {
    top: 20px;
  }
}

p.tabIssuesLabel {
  display: flex;
  align-items: center;
  gap: 4px;
  margin: 0;
  font-weight: 600;

  span {
    color: #5e677b;
    background-color: #f2f4f7;
    padding: 4px;
    width: 28px;
    height: 28px;
    font-size: 10px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  text-transform: none;
}

.scroll {
  overflow-y: auto;
}
