.summaryTab,
.reviewTab {
  width: 100%;
}

.summaryTab {
  .paperSummary {
    display: block;
  }
}

audio + .player .play-time {
  width: 137px;
}
audio + .player .volume-options {
  width: 90px;
}

video + .player .play-time {
  width: 17px;
}
video + .player .player-progress-bar {
  height: 30px;
  margin-top: -10px;
}
video + .player .volume-options {
  width: 14px;
}

.mediaPlayer {
  grid-area: mediaPlayer;
}

.issueComponent {
  font-size: small;
  grid-area: issueComponent;
  position: absolute;
  height: 100%;
  width: 100%;
}

.transcriptComponent {
  grid-area: transcriptComponent;
  z-index: 1001;
  position: absolute;
  height: 100%;
  width: 100%;

  .contextToast {
    position: fixed;
    display: flex;
    justify-content: space-between;
    bottom: 0;
    background: #3478f6;
    border-radius: 5px;
    color: white;
    width: 62%;
    height: 40px;

    &.toast-extended {
      z-index: 1600;
      width: 100%;
    }

    svg {
      position: absolute;
      transform: translateY(-50%);
      top: 50%;
      margin: 0 10px 0 10px;
    }

    p {
      margin: 0;
      margin-left: 35px;
      top: 50%;
      -ms-transform: translateY(-50%);
      position: absolute;
      transform: translateY(-50%);
    }

    .cancel {
      position: relative;
      margin-right: 20px;
      margin-top: 5px;
      font-weight: bold;
      cursor: pointer;
    }
  }
}

.grid {
  display: grid;
  grid-template-columns: calc(36% - 10px) 64%;
  gap: 10px;
  align-items: self-start;
  overflow: auto;
  min-height: 0;
  min-width: 0;
  position: relative;
  height: 77vh;
  grid-template-rows: 0px;
}

.mediaCollapsed {
  grid-template-areas:
    'mediaPlayer transcriptComponent'
    'issueComponent transcriptComponent';

  .issueComponent.audio {
    height: calc(100% - 120px);
    margin-top: 120px;
  }
  .issueComponent.video {
    height: calc(100% - 360px);
    margin-top: 360px;
  }
}

.mediaExtended {
  grid-template-areas:
    'issueComponent mediaPlayer'
    'issueComponent transcriptComponent';

  .transcriptComponent.audio {
    height: calc(100% - 120px);
    margin-top: 120px;
  }
  .transcriptComponent.video {
    height: calc(100% - 530px);
    margin-top: 530px;
  }
}

audio {
  width: 100%;
  border-radius: 0;
  background-color: #f1f3f4;
}

.videoTitle {
  justify-content: space-between;
}

.ruleViolationContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.thumbUp,
.thumbDown,
.edit {
  margin: auto 0;

  .logo-loading-component-container {
    img {
      display: none;
    }
  }
}

.mediaPlayer.audio {
  height: 100px;
}

.full-player {
  position: fixed;
  z-index: 1050;
  bottom: 0;
  width: 100%;
  height: 40px;
  left: 50%;
  transform: translate(-50%);
}

.hide-player {
  display: none;
}

@media only screen and (min-height: 1100px) {
  .mediaCollapsed {
    video {
      height: 400px;
    }
  }

  .mediaExtended {
    video {
      height: 750px;
    }
  }
}

@media only screen and (max-height: 1100px) and (min-height: 830px) {
  .mediaCollapsed {
    height: 76vh !important;

    video {
      height: 300px;
    }
  }

  .mediaExtended {
    height: 76vh !important;

    video {
      height: 460px;
    }
  }
}

@media only screen and (max-height: 830px) {
  .mediaCollapsed {
    height: 71vh !important;

    video {
      height: 200px;
    }

    .issueComponent.video {
      height: calc(100% - 270px);
      margin-top: 270px;
    }
  }

  .mediaExtended {
    height: 71vh !important;

    video {
      height: 325px;
    }

    .transcriptComponent.video {
      height: calc(100% - 390px);
      margin-top: 390px;
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Make sure it is above other elements */
}
