.notes-tab-list-card {
  border-bottom: 1px solid #dcdcdc;
  padding: 16px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  gap: 16px;
  cursor: pointer;
  &:hover {
    background-color: #e7f0fa;
  }
  &.selected {
    background-color: #e7f0fa;
  }
  .notes-tab-list-card-text {
    display: flex;
    flex-direction: column;
    width: 85%;
    .notes-tab-list-card-title {
      color: #5e677b;
      font-weight: 600;
    }
    .notes-tab-list-card-content {
      color: #858e93;
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .notes-tab-list-card-infos {
      display: flex;
      gap: 4px;
      color: #858e93;
      font-size: 12px;
      align-items: center;
      margin-top: 8px;
    }
  }
  .notes-tab-list-card-actions {
    display: flex;
    gap: 8px;
    .edit-action {
      &:hover {
        fill: #469fe9;
      }
    }
  }
}
