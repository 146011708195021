.accordion {
  padding: 18px;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
  button:focus {
    border-color: var(--detail-border) !important;
    box-shadow: unset !important;
  }
  .accordion-item {
    border-radius: 4px;
    box-shadow: unset;
    &:last-child {
      border-bottom: 1px solid var(--detail-border);
    }
  }
}
