.async-autocomplete > .MuiAutocomplete-root > .MuiFormControl-root > .MuiOutlinedInput-root {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 12px;
}

.autocomplete-form-label {
  font-weight: 800;
  color: rgba(0, 0, 0, 0.6) !important;
  margin-bottom: 0.2rem;
}

.hidden {
  display: none;
}

.required-indicator {
  color: var(--warning-error);
}

.async-autocomplete > .MuiAutocomplete-root > .MuiFormControl-root > .MuiOutlinedInput-root.Mui-focused > fieldset {
  border-width: 1px;
}

.async-autocomplete.filter > .MuiAutocomplete-root > .MuiFormControl-root > .MuiOutlinedInput-root {
  padding-top: 0;
  padding-bottom: 0;
}

.async-autocomplete.filter > .MuiAutocomplete-root > .MuiFormControl-root .MuiSvgIcon-root {
  fill: var(--detail-gray) !important;
}

.async-autocomplete.filter > .MuiAutocomplete-root > .MuiFormControl-root > .MuiOutlinedInput-root > fieldset {
  border-top: none;
  border-right: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.45) !important;
  border-left: none;
  border-radius: 0;
  box-shadow: none !important;
}

.async-autocomplete > .MuiAutocomplete-root > .MuiFormControl-root > .MuiOutlinedInput-root > fieldset > legend {
  display: none;
}

.async-autocomplete > .MuiAutocomplete-root > .MuiFormControl-root > .MuiOutlinedInput-root > input::placeholder {
  color: black !important;
}

.async-autocomplete > .MuiAutocomplete-root > .MuiFormControl-root > .MuiOutlinedInput-root > input:focus {
  border-color: transparent !important;
  box-shadow: none !important;
}

.MuiAutocomplete-listbox {
  overflow-x: hidden !important;
  font-size: 12px !important;
  max-height: 200px;
  padding: 8px 0;
}

.MuiAutocomplete-option,
.MuiAutocomplete-noOptions {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 12px;
  padding: 8px 16px;
  box-sizing: border-box;
}

.MuiAutocomplete-input {
  padding: 15px 0px 4px 0px !important;
}

.async-autocomplete > .MuiAutocomplete-root > .MuiFormControl-root > .MuiOutlinedInput-root:focus-within .tag-container {
  opacity: 0.5;
  transition: opacity 0.2s ease;
}

.tag-container {
  display: flex;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  background-color: transparent;
  color: var(--text-black);
  font-size: 12px;
  line-height: 24px;
  max-width: 60%;
  transition: opacity 0.2s ease;
}

.truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tag-number {
  background-color: gray;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding: 0;
  margin-left: 2px;
  font-size: 12px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: white;
  flex-shrink: 0;
}

.hidden-tag-container {
  display: none;
}
