.attachments-tab-container {
  position: absolute;
  top: calc(100% + 16px);
  right: 0;
  background: #fff;
  width: 500px;
  border-radius: 8px;
  border: 1px solid #dcdcdc;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  z-index: 1005;
  .attachments-tab-header {
    position: relative;
    padding: 12px 16px;
    border-bottom: 1px solid #dcdcdc;
    .attachments-tab-title {
      color: #111111;
    }
    svg {
      cursor: pointer;
      position: absolute;
      top: calc(50% - 15px);
      right: 6px;
    }
  }
  .attachments-tab-body {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px 16px;
    gap: 16px;
    max-height: 70vh;
    overflow: auto;
    .attachments-tab-upload {
      display: flex;
      flex-direction: column;
      gap: 16px;
      .upload-drop-zone {
        display: flex;
        flex-direction: column;
        font-weight: normal;
        color: #5e677b;
        border: 1px dashed #5e677b;
        padding: 24px 16px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        .upload-drop-zone-text {
          display: flex;
          flex-direction: column;
          align-items: center;
          .upload-choose-file-button {
            color: #004fff;
            text-decoration: underline;
            cursor: pointer;
          }
          .upload-choose-file-input {
            display: none;
          }
          .upload-drop-zone-max-size {
            svg {
              margin-right: 4px;
            }
            font-size: 14px;
            font-weight: 500;
          }
        }
      }
      .tab-upload-files {
        display: flex;
        flex-direction: column;
        gap: 16px;
        .tab-upload-files-title-container {
          width: 100%;
          display: flex;
          justify-content: space-between;
          .tab-upload-files-title {
            color: #444;
          }
          .tab-upload-files-action {
            cursor: pointer;
            text-decoration: underline;
            color: #dc3545;
            font-weight: normal;
          }
        }
        .files-upload-cards {
          display: flex;
          flex-direction: column;
          gap: 8px;
          .files-upload-card {
            display: flex;
            border: 1px solid #dcdcdc;
            border-radius: 4px;
            flex-direction: column;
            width: 100%;
            padding: 8px;
            .files-upload-card-body {
              display: flex;
              gap: 16px;
              align-items: center;
              justify-content: space-between;
              width: 100%;
              .files-upload-card-content {
                display: flex;
                flex-direction: column;
                width: 100%;
                .files-upload-card-title {
                  color: #444;
                  font-size: 14px;
                  font-weight: 600;
                }
                .files-upload-card-infos {
                  display: flex;
                  gap: 4px;
                  align-items: center;
                  .files-upload-card-info {
                    display: flex;
                    align-items: center;
                    gap: 4px;
                    color: #5e677b;
                    font-size: 12px;
                  }
                  .files-upload-card-error {
                    display: flex;
                    align-items: center;
                    color: #dc3545;
                    font-size: 12px;
                  }
                  .files-upload-card-completed {
                    display: flex;
                    align-items: center;
                    color: #28a745;
                    font-size: 12px;
                  }
                }
              }
              .files-upload-card-actions {
                display: flex;
                gap: 8px;
                align-items: center;
                svg {
                  cursor: pointer;
                }
              }
            }
            .progress-bar-track {
              width: 100%;
              background-color: #dcdcdc;
              height: 4px;
              border-radius: 2px;
              .progress-bar {
                height: 100%;
                background-color: #2cda9b;
                border-radius: 2px;
              }
            }
          }
        }
      }
      .tab-upload-actions {
        display: flex;
        gap: 16px;
        .tab-upload-button-cancel {
          background-color: transparent;
          color: #5e677b;
          border: 1px solid #5e677b;
          width: 100%;
          &:hover {
            border: 1px solid #dc3545;
            color: #dc3545;
          }
          &:focus {
            box-shadow: none;
          }
        }
        .tab-upload-button-upload {
          background-color: #2cda9b;
          color: #fff;
          border: none;
          transition: 0.5s;
          width: 100%;
          &:hover {
            background-color: #25bb84;
            color: #fff;
            transition: 0.5s;
          }
          &:focus {
            box-shadow: none;
          }
          &:disabled {
            opacity: 0.65;
          }
        }
      }
      .tab-upload-notice {
        color: #dc3545;
        font-size: 13px;
        text-align: center;
        margin: 0;
        font-weight: normal;
      }
    }
    .attachments-tab-files {
      display: flex;
      flex-direction: column;
      gap: 16px;
      .files-cards {
        display: flex;
        flex-direction: column;
        gap: 8px;
        .files-card {
          display: flex;
          border: 1px solid #dcdcdc;
          border-radius: 4px;
          gap: 16px;
          padding: 8px;
          align-items: center;
          justify-content: space-between;
          .files-card-content {
            width: 100%;
            .files-card-title {
              color: #444;
              font-size: 14px;
              font-weight: 600;
            }
            .files-card-infos {
              display: flex;
              align-items: center;
              gap: 4px;
              color: #5e677b;
              font-size: 12px;
              font-weight: 500;
            }
          }
          .files-card-actions {
            display: flex;
            justify-content: center;
            align-items: center;
            svg {
              cursor: pointer;
            }
            .delete-action-container {
              margin-left: 8px;
            }
          }
        }
      }
      .no-attachments {
        margin: 0;
        text-align: center;
        color: #5e677b;
      }
    }
  }
}
