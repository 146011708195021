.multi-state-switch-container {
  display: flex;
  width: 100%;
  align-items: center;
  .multi-state-switch-label {
    margin-right: 8px;
  }
  .multi-state-switch-options {
    display: inline-flex;
    align-items: center;
    padding: 2px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-weight: 600;
    border-radius: 0.375rem;
    background: #edf2f7;
    list-style: none;
    margin: 0;
    width: 100%;
    .multi-state-switch-option {
      display: flex;
      text-align: -webkit-match-parent;
      unicode-bidi: isolate;
      align-items: center;
      width: 100%;
      justify-content: center;
      transition: background-color 0.3s;
      .multi-state-switch-option-input {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border-width: 0;
      }
      .multi-state-switch-option-label {
        cursor: pointer;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
        color: #718096;
        width: 100%;
        text-align: center;
        user-select: none;
        &:hover {
          color: #000000de;
        }
        &.selected {
          background: #fff;
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
          border-radius: 0.25rem;
          color: #111;
        }
      }
    }
  }
}
