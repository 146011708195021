.edit-submission-tab-container {
  .edit-submission-tab-body {
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 16px;
    .edit-submission-inputs {
      display: flex;
      gap: 32px;
      .edit-submission-tab-content-col {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 8px;
        .custom-select-container {
          min-height: 42px;
          margin: 0;
          .select-label {
            width: 50%;
          }
          .select-button {
            min-height: 25px;
          }
        }
        .edit-submission-input-container {
          display: flex;
          width: 100%;
          min-height: 42px;
          align-items: center;
          .edit-submission-input-label {
            font-size: 14px;
            width: 50%;
            color: #000;
          }
          .cc-input {
            width: 100%;
            height: 0;
            min-height: 25px;
            border: 1px solid #aaa;
            border-radius: 0.25rem;
            padding: 8px;
            margin-left: 2px;
            font-size: 14px;
            &:focus {
              outline: none;
            }
          }
        }
        .levels-container {
          display: flex;
          align-items: center;
          gap: 16px;
          .edit-submission-input-label {
            width: unset;
          }
          .levels-groups {
            display: flex;
            gap: 16px;
            .levels-group {
              display: flex;
              flex-direction: column;
              input {
                cursor: pointer;
                margin-right: 8px;
              }
            }
          }
        }
        .checkbox-container {
          display: flex;
          align-items: center;
          input {
            cursor: pointer;
            margin-right: 8px;
          }
        }
      }
    }
  }
  .edit-submission-footer {
    display: flex;
    gap: 16px;
    justify-content: end;
    position: sticky;
    margin: 0;
    padding: 8px 16px;
    position: sticky;
    bottom: 0;
    left: 0;
    background: white;
    width: 100%;
    border-top: 1px solid #dcdcdc;
    button {
      max-width: 160px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .edit-submission-button-reset {
      background-color: transparent;
      color: #5e677b;
      border: 1px solid #5e677b;
      width: 100%;
      &:hover {
        border: 1px solid #dc3545;
        color: #dc3545;
      }
      &:focus {
        box-shadow: none;
      }
    }
    .edit-submission-button-confirm {
      background-color: #2cda9b;
      color: #fff;
      border: none;
      transition: 0.5s;
      width: 100%;
      &:hover {
        background-color: #25bb84;
        color: #fff;
        transition: 0.5s;
      }
      &:focus {
        box-shadow: none;
      }
      &:disabled {
        opacity: 0.65;
      }
    }
  }
}
