.violation-tag-container {
  padding: 3px 6px;
  border-radius: 5px;
  margin: 0;
  margin-left: 6px;
  box-sizing: border-box;
  max-height: 24px;
  span {
    font-weight: 700;
  }
  &.violation-tag-info {
    background: rgba(94, 103, 123, 0.2);
    span {
      color: #5e677b;
    }
  }
  &.violation-tag-alert {
    background: rgba(216, 0, 39, 0.2);
    span {
      color: #d80027;
    }
  }
}
