.edit-review-template-tab-container {
  .edit-review-template-tab-body-edit {
    margin: 40px;
  }
  .actionsContainer {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    border-top: 1px solid;
    border-color: #eaeaf0;
    margin: 0;
    padding: 20px;
    position: sticky;
    bottom: 0;
    left: 0;
    background: #fff;
    border-radius: 0 0 4px 4px;

    .rightContent {
      gap: 10px;
      display: flex;
      align-items: center;

      * {
        min-width: 100px;
      }

      .cancelButton {
        background: none;
        border: none;
        color: red;
      }
      .testButton {
        color: #333;
        background-color: #eaeaf0 !important;
        border-color: #eaeaf0 !important;
        &:disabled {
          color: #aaa;
        }
        &:focus {
          box-shadow: none;
        }
      }
      .nextButton {
        background-color: #2bd999 !important;
        border-color: #2cda9b !important;
        &:focus {
          box-shadow: none;
        }
      }
    }
  }
  .edit-review-template-tab-body {
    .add-new-container {
      display: flex;
      flex-direction: row-reverse;
      margin: 40px;

      .add-new {
        background: #2bd999;
        color: white;
        padding: 10px;
        width: 140px;
        font-weight: 700;
        border: none;
      }
    }
    .templateContainer {
      margin: 40px;
      gap: 20px;
      display: flex;
      flex-wrap: wrap;

      button:focus {
        border-color: unset !important;
        box-shadow: unset !important;
      }

      .template {
        width: 138px;
        height: 138px;
        border-radius: 5px;
        border: 1px solid #dcdcdc;
        background: #f2f4f7;
        position: relative;

        svg {
          width: 48px;
          height: 48px;
          margin-top: 20px;
          color: #dcdcdc !important;

          &.selected {
            color: #3478f6 !important;
          }
        }

        p {
          padding-top: 10px;
          color: #5e677b;
          font-size: 12px;
          line-height: 14.4px;

          &.selected {
            color: #3478f6 !important;
          }
        }

        .actions {
          height: 20px;
          text-align: end;
          display: flex;
          position: absolute;
          right: 0;
          top: 0;

          svg {
            max-width: 20px;
            max-height: 20px;
            margin: 0;
            color: black !important;
          }

          .template-delete {
            color: red !important;
          }
          .template-enable {
            color: green !important;
          }
        }
      }
    }
  }
}

.template-name-input-container, .email-input-container {
  display: flex;
  margin-top: 16px;
  align-items: center;
  gap: 16px;
  margin-bottom: 32px;
  input {
    width: 100%;
    border: 1px solid #dadada;
    border-radius: 6px;
    padding: 9px 16px;
  }
}

.confirmationModal {
  .modal-content {
    min-width: 320px;
  }

  .modal-footer {
    align-self: center;
    justify-content: end;
    width: 100%;

    .footerContainer {
      display: flex;
    }

    button {
      font-size: 14px;
    }
  }
}

.input-error {
  border-color: red !important;

  &:focus,
  &:focus-visible {
    border-color: red !important;
    box-shadow: unset !important;
    outline: none;
  }
}

.email-input-container {
  margin-bottom: 0px !important;
}

.error-message {
  margin-left: 110px;
}

.submitButton {
  margin: 0 0 0 16px;
}

.backButton {
  background-color: #dcdcdc;
  color: #333;
  border: none;
}
