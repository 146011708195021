.categories-tab-container {
  position: absolute;
  top: calc(100% + 16px);
  right: 0;
  background: #fff;
  width: 500px;
  border-radius: 8px;
  border: 1px solid #dcdcdc;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  z-index: 1005;
  .categories-tab-header {
    position: relative;
    padding: 12px 16px;
    border-bottom: 1px solid #dcdcdc;
    .categories-tab-title {
      color: #111111;
    }
    svg {
      cursor: pointer;
      position: absolute;
      top: calc(50% - 15px);
      right: 6px;
    }
  }
  .categories-tab-alerts-container {
    padding: 0 16px;
    padding-top: 8px;
    .alert-container {
      margin: 0;
    }
  }
  .categories-tab-body {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px 16px;
    padding-bottom: 0;
    max-height: 70vh;
    .custom-select-container {
      margin: 0;
      .select-label {
        margin: 0;
        width: 23%;
        font-weight: normal;
      }
      .select-button {
        min-height: 25px;
      }
      ul {
        font-weight: normal;
      }
    }
    .categories-input-container {
      display: flex;
      width: 100%;
      .categories-input-label {
        width: 23%;
        font-size: 14px;
        font-weight: normal;
        color: #000;
      }
      .cc-input {
        width: 100%;
        height: 0;
        min-height: 25px;
        border: 1px solid #aaa;
        border-radius: 0.25rem;
        padding: 8px;
        font-size: 14px;
        &:focus {
          outline: none;
        }
      }
    }
  }
  .categories-footer {
    display: flex;
    gap: 16px;
    justify-content: end;
    margin: 0;
    padding: 16px 0;
    background: white;
    width: 100%;
    border-top: 1px solid #dcdcdc;
    button {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .categories-button-reset {
      background-color: transparent;
      color: #5e677b;
      border: 1px solid #5e677b;
      width: 100%;
      &:hover {
        border: 1px solid #dc3545;
        color: #dc3545;
      }
      &:focus {
        box-shadow: none;
      }
    }
    .categories-button-confirm {
      background-color: #2cda9b;
      color: #fff;
      border: none;
      transition: 0.5s;
      width: 100%;
      &:hover {
        background-color: #25bb84;
        color: #fff;
        transition: 0.5s;
      }
      &:focus {
        box-shadow: none;
      }
      &:disabled {
        opacity: 0.65;
      }
    }
  }
}
