.upload-new-file-modal {
  .modalBody {
    .upload-new-file-content {
      display: flex;
      flex-direction: column;
      gap: 16px;
      .upload-drop-zone {
        display: flex;
        flex-direction: column;
        font-weight: normal;
        color: #5e677b;
        border: 1px dashed #5e677b;
        padding: 24px 16px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        .upload-drop-zone-text {
          display: flex;
          flex-direction: column;
          align-items: center;
          .upload-choose-file-button {
            color: #004fff;
            text-decoration: underline;
            cursor: pointer;
          }
          .upload-choose-file-input {
            display: none;
          }
        }
      }
      .upload-file-container {
        display: flex;
        flex-direction: column;
        gap: 16px;
        .upload-file-title-container {
          width: 100%;
          display: flex;
          justify-content: space-between;
          .tab-upload-files-title {
            color: #444;
          }
          .tab-upload-files-action {
            cursor: pointer;
            text-decoration: underline;
            color: #dc3545;
            font-weight: normal;
          }
        }
        .files-upload-card {
          display: flex;
          border: 1px solid #dcdcdc;
          border-radius: 4px;
          flex-direction: column;
          width: 100%;
          padding: 8px;
          .files-upload-card-body {
            display: flex;
            gap: 16px;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            .files-upload-card-content {
              display: flex;
              flex-direction: column;
              width: 100%;
              .files-upload-card-title {
                color: #444;
                font-size: 14px;
                font-weight: 600;
              }
              .files-upload-card-infos {
                display: flex;
                gap: 4px;
                align-items: center;
                .files-upload-card-info {
                  display: flex;
                  align-items: center;
                  gap: 4px;
                  color: #5e677b;
                  font-size: 12px;
                }
                .files-upload-card-error {
                  display: flex;
                  align-items: center;
                  color: #dc3545;
                  font-size: 12px;
                }
                .files-upload-card-completed {
                  display: flex;
                  align-items: center;
                  color: #28a745;
                  font-size: 12px;
                }
              }
            }
            .files-upload-card-actions {
              display: flex;
              gap: 8px;
              align-items: center;
              svg {
                cursor: pointer;
              }
            }
          }
          .progress-bar-track {
            width: 100%;
            background-color: #dcdcdc;
            height: 4px;
            border-radius: 2px;
            .progress-bar {
              height: 100%;
              background-color: #2cda9b;
              border-radius: 2px;
            }
          }
        }
      }
    }
  }
  .modal-footer {
    width: 100%;
    margin: 0;
    .actions {
      display: flex;
      gap: 16px;
      width: 100%;
      .cancel-button {
        background-color: transparent;
        color: #5e677b;
        border: 1px solid #5e677b;
        width: 100%;
        &:hover {
          border: 1px solid #dc3545;
          color: #dc3545;
        }
        &:focus {
          box-shadow: none;
        }
        &:disabled {
          color: #a5a5a5;
          border: 1px solid #a5a5a5;
        }
      }
      .upload-button {
        background-color: #2cda9b;
        color: #fff;
        border: none;
        transition: 0.5s;
        width: 100%;
        &:hover {
          background-color: #25bb84;
          color: #fff;
          transition: 0.5s;
        }
        &:focus {
          box-shadow: none;
        }
        &:disabled {
          opacity: 0.65;
        }
      }
    }
  }
}
