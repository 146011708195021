.custom-language-select {
  border: none;
  border-left: 1px solid rgba(0, 0, 0, 0.1);

  div {
    border: none;
    border-radius: 0;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;

    &.css-13cymwt-control,
    &.css-t3ipsp-control,
    &.css-16xfy0z-control {
      height: 39px;
    }
  }

  .opt {
    display: flex;
    align-items: center;
    gap: 5px;
    height: 39px;
  }

  .css-1u9des2-indicatorSeparator {
    background: none;
  }
}
