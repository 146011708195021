.error-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 56px);
}

.error-screen h2,
.error-screen h4 {
  color: #222222;
}

.error-screen h1 {
  font-weight: bold;
  font-size: 144px;
  color: #715fcc;
  margin-bottom: 23px;
}

.error-screen h2 {
  font-weight: bold;
  font-size: 48px;
  margin-bottom: 15px;
}

.error-screen h4 {
  font-size: 20px;
  margin-bottom: 102px;
}

.error-screen .btn {
  width: 100%;
  height: 39px;
  max-width: 360px;
  padding-top: 11px;
}
