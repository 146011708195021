.title {
  color: black;
  font-weight: 600;
  margin: auto 0;
}

.modalBody {
  padding: 10px 15px;
  border-bottom: 1px solid;
  border-top: 1px solid;
  border-color: #eee;

  .contextHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;

    .actions {
      display: flex;
      gap: 20px;

      .changeContext {
        color: #3478f6;
        cursor: pointer;
        margin: auto 0;
      }
    }
  }

  .description {
    word-break: break-word;
    background: #ffeca7;
  }
}

.modal-footer {
  align-self: end;
  margin-bottom: initial;

  button {
    width: 130px;
    height: 32px;
    align-items: center;
    justify-content: center;
    display: flex;
  }
}

.dropdown-toggle {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white !important;
  color: #444 !important;
  border-color: #aaa;
  overflow: hidden;
}

.dropdown-menu {
  width: 300px;
  height: 290px;
  border-radius: 0.25rem 0 0 0.25rem;
  line-height: 15px;
  margin-left: -1px;

  // Only allow the dropdown to open from bottom to match the right panel position.
  inset: 0 !important;
  transform: translate(0px, 40px) !important;

  overflow: scroll;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
}

.dropdown-header {
  color: black;
  font-weight: bold;
  margin-top: 10px;
}

.dropdown-item {
  color: #444;
}

.right-panel {
  background: #f2f2f2;
  min-width: 300px;
  position: absolute;
  transform: translate(297px, 2px);
  border: 1px solid rgba(0, 0, 0, 0.15);
  height: 290px;
  border-radius: 0 0.25rem 0.25rem 0;
  padding-top: 20px;
  line-height: 15px;
  overflow: scroll;
  z-index: 99;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
}

.ruleViolationContainer {
  display: flex;
  gap: 10px;
  flex-direction: column;
  // max-height: 400px;
  // overflow: scroll;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }

  .selectionSection {
    width: 95%;

    .ruleViolationDescription {
      margin-top: 5px;
      width: 100%;
      padding: 10px;
      border-radius: 0.25rem;
      border-color: #aaa;
      color: #444;
    }
  }

  .row {
    .controlSection {
      margin: 0;
      padding: 0;
      font-size: 25px;
      width: fit-content;

      .remove {
        color: #ff5216;
        cursor: pointer;
      }

      .add {
        color: #2cda9b;
        cursor: pointer;
      }
    }
  }
}

textarea:disabled {
  cursor: not-allowed;
}

.last-item {
  display: flex;
  flex-direction: column;
}

.selection-header {
  margin: 20px 0 10px 0;
  color: black;
  font-weight: 600;
}

.selection-container,
.rule-violation-container {
  margin: 20px 0 0 0;
}

.change-selection {
  margin: 10px auto;
  display: flex;
}

.violationRuleModal {
  button {
    font-size: 14px;
  }

  button:focus {
    border-color: unset !important;
    box-shadow: unset !important;
  }

  .backButton {
    color: #333;
    background: #dcdcdc;
    margin-top: 0px;
    border: unset;
  }
}

.rv-modal-footer {
  display: block;
  width: 100%;
  .rvModalActions {
    display: flex;
    justify-content: space-between;

    .leftContent {
      margin-left: 20px;
    }

    .rightContent {
      display: flex;
      gap: 10px;
    }
  }
}
