.error-code-management-container {
  .error-code-management-papers {
    display: flex;
    width: 100%;
    gap: 16px;
    padding: 24px 16px;
    .error-code-management-list-container {
      width: 100%;
      .paper-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 16px;
        .button-container {
          display: flex;
          align-items: center;
          gap: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #2cda9b;
          border-radius: 4px;
          padding: 0px 8px;
          height: 30px;
          cursor: pointer;
          .button-text {
            font-size: 12px;
            color: #2cda9b;
          }
          &:hover {
            background: #2cda9b;
            svg {
              fill: #fff;
            }
            .button-text {
              color: #fff;
            }
          }
        }
      }
      .error-code-management-list-content {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 16px;
        height: 83vh;
        overflow: auto;
        svg {
          color: black;
          cursor: pointer;
        }
        .vr-container {
          .level1-container {
            .level1-name {
              padding: 4px 0;
              color: black;
              font-size: 14px;
              font-weight: bold;
              width: 100%;
            }
          }
          .error-code-item {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1216);
            cursor: pointer;
            gap: 4px;
            .error-code-dots-container {
              text-align: center;
              padding: 4px 0;
              width: 30px;
              &:hover {
                background: #d3e5fa;
              }
            }
            .level-menu {
              display: flex;
              flex-direction: column;
              background: #fff;
              padding: 8px 0px;
              position: absolute;
              right: 0;
              top: 100%;
              border-radius: 4px;
              box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
              z-index: 500;
              min-width: 125px;
              .level-menu-item {
                font-size: 12px;
                font-weight: 500;
                padding: 8px;
                &:hover {
                  background: #e7f0fa;
                }
              }
            }
            &:hover {
              background: #e7f0fa;
            }
          }
          .vrc-container {
            padding-left: 24px;
            .level2-container {
              .level2-name {
                color: #444444;
                font-size: 16px;
                font-weight: 500;
                width: 100%;
                padding: 4px 0;
              }
            }
            .vrcc-container {
              padding-left: 32px;
              .level3-container {
                .level3-name {
                  color: #757575;
                  font-size: 16px;
                  font-weight: 500;
                  width: 100%;
                  padding: 4px 0;
                }
              }
            }
          }
          .disabled-error-code {
            background: #f0f0f0;
            [class^='level'][class*='-name'] {
              color: #b5b5b5 !important;
              font-weight: normal;
            }
          }
          .selected-error-code {
            background: #e7f0fa;
          }
          .level-item-chevron {
            padding: 4px;
            &:hover {
              background: #d3e5fa;
            }
          }
          .level-item-chevron-disabled {
            svg {
              fill: #afafaf;
            }
            &:hover {
              background: #e7f0fa;
            }
          }
          .level-item-container {
            display: flex;
            gap: 4px;
            align-items: center;
            justify-content: center;
            width: 100%;
          }
        }
      }
    }
    .error-code-management-paper-container {
      width: 100%;
      .error-code-management-content {
        display: flex;
        flex-direction: column;
        gap: 32px;
        padding: 16px;
        .error-code-management-form {
          display: flex;
          flex-direction: column;
          gap: 16px;
          .error-code-management-input-container {
            max-width: 100%;
            min-height: 42px;
            .error-code-management-input-label {
              font-size: 14px;
              color: #000;
              margin-bottom: 4px;
            }
            .cc-input {
              width: 100%;
              height: 0;
              min-height: 25px;
              border: 1px solid #aaa;
              border-radius: 0.25rem;
              padding: 8px;
              font-size: 14px;
              &:focus {
                outline: none;
              }
            }
          }
          .error-code-management-checkbox-container {
            display: flex;
            align-items: center;
            .error-code-management-checkbox {
              cursor: pointer;
              margin-right: 8px;
            }
            .error-code-management-checkbox-label {
              display: flex;
              align-items: center;
              div {
                padding-bottom: 2px;
                svg {
                  margin-left: 4px;
                }
              }
            }
          }
        }
      }
      .error-code-management-footer {
        display: flex;
        gap: 16px;
        justify-content: end;
        width: 100%;
      }
      .custom-select-container {
        margin: 0;
      }
    }
    .loading-screen {
      .logo-loading-component-container {
        height: 100%;
      }
    }
  }
}
