.container.testing-tool {
  padding: 0;
}

.container.testing-tool .card {
  margin: 32px 24px;
}

.container.testing-tool .card-body {
  margin-top: 28px;
  margin-left: 32px;
  margin-right: 38px;
  margin-bottom: 45px;
  padding: 0 !important;
}

.selectGraph {
  margin-bottom: 20px;
}

.columnTitle {
  font-weight: 10000;
  font-size: 100px;
}

.flex {
  display: flex;
  width: 100%;
  align-items: center;
}

.table {
  max-height: 600px;
  overflow-y: auto;
  position: relative;
  &.min-height {
    min-height: 200px;
  }

  td,
  th {
    padding: 6px 0;
  }
  th span {
    cursor: pointer;
  }

  .loader-container {
    position: absolute;
    top: 96px;
    left: calc(50% - 24.5px);
  }
}

.summary {
  width: 33%;
}

.tableRow {
  color: black;

  a:hover,
  .table-icon:hover {
    cursor: pointer;
  }

  &.selectedRow {
    background-color: rgb(137, 137, 237);
    color: white !important;
  }

  .speaker-full-name {
    margin-bottom: 0 !important;
  }

  .transcript-ready {
    color: green;
  }
}

.tableSubRow {
  display: none !important;
  &.open {
    display: table-row !important;
  }
}

.flexTop {
  display: flex;
  justify-content: flex-start;
  /* or justify-content: flex-end; for right alignment */
}

.loading-card {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
}

.input-search {
  width: 100%;
}

.title {
  display: flex;
}

.options {
  padding: 0 20px 20px;

  .input-label-search {
    padding: 0px 0px 4px 0px;
  }
  .id-search {
    width: 50%;
    padding: 0% 10% 0% 0%;
  }
  .id-filter {
    width: 30%;
    margin: 0% 1% 0% 0%;
  }
}
