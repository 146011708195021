.notes-tab-container {
  .notes-tab-papers {
    display: flex;
    width: 100%;
    gap: 16px;
    .notes-tab-list-container {
      width: 30%;
      .paper-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 16px;
      }
      .new-note-container {
        display: flex;
        align-items: center;
        gap: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #2cda9b;
        border-radius: 4px;
        padding: 0px 8px;
        height: 30px;
        cursor: pointer;
        .new-note-text {
          font-size: 12px;
          color: #2cda9b;
        }
        &:hover {
          border: none;
          background: #2cda9b;
          svg {
            fill: #fff;
          }
          .new-note-text {
            color: #fff;
          }
        }
      }
      .notes-tab-list-content {
        height: 70vh;
        overflow: auto;
        .notes-tab-list-cards {
          display: flex;
          flex-direction: column;
          overflow: auto;
          :last-child {
            border-bottom: none;
          }
          .notes-tab-list-empty {
            display: flex;
            padding: 32px;
            justify-content: center;
            margin: 0;
          }
        }
      }
    }
    .notes-tab-note-container {
      width: 70%;
      .notes-tab-note-content {
        padding: 16px;
        height: 100%;
        .notes-tab-note-content-inputs {
          display: flex;
          flex-direction: column;
          gap: 16px;
          height: 100%;
          input {
            border: 1px solid rgba($color: #000000, $alpha: 0.1);
            border-radius: 6px;
            padding: 9px 16px;
          }
          textarea {
            border: 1px solid rgba($color: #000000, $alpha: 0.1);
            border-radius: 6px;
            padding: 16px;
            height: 40%;
          }
          .notes-tab-note-content-inputs-buttons {
            display: flex;
            justify-content: end;
            gap: 16px;
            .inputs-cancel-button {
              background-color: transparent;
              color: #5e677b;
              border: 1px solid #5e677b;
              width: 100%;
              max-width: 150px;
              &:hover {
                border: 1px solid #dc3545;
                color: #dc3545;
              }
              &:focus {
                box-shadow: none;
              }
            }
            .inputs-submit-button {
              background: #2cda9b;
              border: none;
              width: 100%;
              max-width: 150px;
              &:hover {
                background-color: #27c289;
              }
              &:focus {
                box-shadow: none;
              }
            }
          }
        }
        .notes-tab-note-content-content {
          p {
            white-space: pre-line;
          }
        }
      }
    }
  }
}
