.history-tab-container {
  .history-tab-content {
    padding: 16px 32px;
    .history-tab-table-container {
      th {
        font-weight: bold;
      }
    }
  }
}
