.analystDecision {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: small;
  gap: 16px;
}

.decisionTitle {
  color: #aaaaaa;
  padding: 0lvh 1lvh 0lvh 2lvh;
}

.issuesProgress {
  color: #aaaaaa;
  font-size: 12px;
  margin-left: 10px;

  .progress {
    height: 0.5rem;

    .progress-bar {
      background: #2cda9b;
    }
  }
}

.submitReview {
  font-size: small;
  align-self: flex-start;
  background-color: #2bd999 !important;
  border-color: #2cda9b !important;
  border-radius: 5px;
  padding: 3px 7px !important;
}

.modalTitle {
  color: #5e677b;
  font-size: medium;
}

.modalBody {
  padding: 3% 5%;
  color: #444;

  .css-ahj2mt-MuiTypography-root {
    font-size: small;
  }
}

.disagreeButton {
  font-size: small;
  border-radius: 5px;
  margin-top: 0px !important;
  padding: 5px 10px !important;
  background-color: white;
  color: #444;
  border-color: #444;

  &:hover {
    background-color: white;
    color: #444;
    border-color: #444;
  }
}

.submitButton {
  font-size: small;
  background-color: #2cda9b !important;
  border-color: #2cda9b !important;
  border-radius: 4px;
  margin-top: 0px !important;
  border-top: 0px solid transparent !important;
  padding: 5px 10px !important;
}

.decisionMessage {
  align-self: center;
  text-align: center;
  padding: 0px 10px;
}

.decisionValue {
  display: flex;
  align-items: center;
}

.analyst-decision-info {
  display: flex;
  align-items: center;
  gap: 16px;
  .analyst-decision-button {
    &.review-again {
      border: #2bd999;
      background-color: #2bd999;
      &:hover {
        background-color: #27c289;
      }
    }
    &.cancel-review {
      border: #dc3545;
      background-color: #dc3545;
    }
  }
}
