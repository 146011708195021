.transcriptComponent {
  line-height: 130%;
  padding-bottom: 20px;

  .transcriptMenu {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    p {
      margin: 0;
      &.transcriptTitle {
        margin-left: 16px;
      }
    }
    .functionals {
      display: flex;
      align-items: center;
      height: 40px;
      button {
        background: none;
        border: none;
        height: 39px;
        &:focus {
          box-shadow: none !important;
          background-color: none;
        }
      }
      .optButtons {
        display: flex;
        button {
          display: flex;
          align-items: center;
          justify-content: center;
          background: none;
          border: none;
          border-left: 1px solid rgba(0, 0, 0, 0.1);
          padding: 8px;
          &:focus {
            border-color: transparent !important;
            box-shadow: none !important;
          }
          svg {
            width: 14px;
            height: 14px;
            stroke-width: 0.1px;
            &.textTop,
            &.texBottom {
              width: 20px;
              height: 20px;
            }
            &.textTop {
              margin-top: 5px;
            }
            &.texBottom {
              margin-top: -8px;
            }
            &.expand {
              margin-top: -2px;
            }
          }
        }
      }
      .videoButton {
        svg {
          color: rgba(52, 120, 246, 1);
        }
      }
      .optButtons {
        display: flex;
      }
    }
  }

  &.full {
    padding-top: 56px;
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 1050;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    transition: all 0.2s linear;

    .transcriptParagraph {
      flex: 1;
      max-height: none;
      height: auto;
      transition: all 0.2s linear;
      padding: 0.5in 2.5in;
      height: 100%;
    }
  }
}

.paperTranscript {
  height: 100%;
}

.transcriptParagraph {
  font-family: 'Times New Roman', Times, serif;
  text-align: justify;
  height: calc(100% - 50px);
  font-size: 12pt;
  font-weight: 500;
  padding: 0.5in 1in;
  line-height: 1.5;
}

.transcriptMenu {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  p {
    margin: 0;

    &.transcriptTitle {
      margin-left: 16px;
    }
  }

  .functionals {
    display: flex;
    align-items: center;
    height: 40px;

    .multi-state-switch {
      margin-right: 12px;
    }

    button {
      background: none;
      border: none;
      height: 39px;

      &:focus {
        box-shadow: none !important;
        background-color: none;
      }
    }

    .optButtons {
      display: flex;

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        background: none;
        border: none;
        border-left: 1px solid rgba(0, 0, 0, 0.1);
        padding: 8px;

        &:focus {
          border-color: transparent !important;
          box-shadow: none !important;
        }

        svg {
          width: 14px;
          height: 14px;
          stroke-width: 0.1px;

          &.textTop,
          &.texBottom {
            width: 20px;
            height: 20px;
          }

          &.textTop {
            margin-top: 5px;
          }
          &.texBottom {
            margin-top: -8px;
          }
          &.expand {
            margin-top: -2px;
          }
        }
      }
    }

    .videoButton {
      svg {
        color: rgba(52, 120, 246, 1);
      }
    }

    .optButtons {
      display: flex;
    }
  }
}

#ruleViolationSelected {
  width: 100%;
  padding: 10px 20px;
  background-color: rgba(231, 240, 250, 1);

  div.issueCard {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 3px 6px;
    border-radius: 5px;

    img {
      width: 18px;
    }
  }

  p {
    margin: 0;
  }
}

.transcriptActionsDiv {
  position: absolute;
  width: 145px;
  height: fit-content;
  font-size: 12px;
  background: white;
  display: flex;
  flex-direction: column;
  border-radius: 5px 5px 5px 5px;
  border: 1px solid #e5e5e5;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  cursor: pointer;

  .transcriptActionContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-bottom: 1px solid;
    border-color: rgba(0, 0, 0, 0.2);
    background: lavender;
    font-weight: bold;
  }

  .itemContainer {
    display: flex;
    min-height: 30px;
    align-items: center;
    margin-left: 4px;
    color: #5e677b;
    font-family: Lato;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    gap: 4px;
    background: none;
    border: none;

    .icon {
      font-size: 18px;
      display: flex;
    }

    .buttonText {
      position: absolute;
      font-size: 12px;
      margin-left: 22px;
    }
  }

  .divider {
    background-color: rgba(0, 0, 0, 0.2);
    width: 145px;
    min-height: 1px;
  }
}
