.summaryCustomModal {
  .modal-dialog {
    max-width: 600px;
  }

  .bodyContainer {
    align-items: normal;

    .select-label {
      min-width: 100px;
    }

    .select-button {
      min-height: 24px;
    }

    .select-dropdown {
      z-index: 99;
    }
    .note-container {
      margin-top: 16px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      height: 100%;
      p {
        margin: 0;
      }
      input {
        font-size: 14px;
        border: 1px solid rgba($color: #000000, $alpha: 0.1);
        border-radius: 6px;
        padding: 9px 16px;
      }
      textarea {
        font-size: 14px;
        border: 1px solid rgba($color: #000000, $alpha: 0.1);
        border-radius: 6px;
        padding: 16px;
        height: 40%;
      }
    }
  }

  .modal-footer {
    justify-content: end;
  }
}

.summaryTab {
  .alert-click-here {
    color: #0d6efd;
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      color: #2cda9b;
    }
  }
  .summary-row {
    margin-bottom: 2%;
  }
}

.paperSubmission {
  width: 35%;

  .paper-title {
    display: flex;
    justify-content: space-between;
    padding: 0 20px 0 0;

    svg {
      cursor: pointer;
    }
  }
}

.paperSummary {
  width: 65%;
}

.paperDownload {
  .downloadDescription {
    margin: 10px;
    text-align: center;
  }

  .downloadBox {
    width: 200px;
    height: 40px;
    border: 1px solid #2bd999;
    margin: 20px auto;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: #2bd999;
    transition: background-color 0.3s ease, border-color 0.3s ease;
    appearance: none;
    color: inherit;
    text-decoration: none;

    &:hover {
      opacity: 0.9;
    }

    svg {
      width: 26px;
      height: 26px;
      margin-right: 8px;
    }

    .downloadText {
      font-size: 16px;
      font-weight: 500;
      color: #fff;
    }
  }
}

.submissionSummary {
  resize: none;
  text-align: justify;
  font-size: small;
}

.submissionSummary:focus {
  outline: none !important;
  box-shadow: none !important;
}

.grouped-details {
  display: flex;
}
.submissionDetails {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
}
.submissionDetail {
  font-weight: 600;
  font-size: small;
  color: black;
  .detailTitle {
    color: #aaaaaa;
    padding: 0lvh 1lvh 0lvh;
  }
}

.detailsValue.accept {
  color: #2cda9b;
}

.detailsValue.reject {
  color: #ff5216;
}

.paperBody {
  padding: 10px;
  color: #444;
}

#summary-text {
  h1 {
    font-size: 18.18px;
    font-weight: bold;
  }
  h2 {
    font-size: 16.98px;
    font-weight: bold;
  }
  h3 {
    font-size: 15.85px;
    font-weight: bold;
  }
  h4 {
    font-size: 14.79px;
    font-weight: bold;
  }
  h5 {
    font-size: 13.8px;
    font-weight: bold;
  }
  h6 {
    font-size: 13.2px;
    font-weight: bold;
  }
}
