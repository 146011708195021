.reports-container {
  width: 100%;
  gap: 16px;
  padding: 24px 16px;
  height: 100%;
  .reports-paper {
    height: 100%;
    .reports-content {
      display: flex;
      flex-direction: column;
      padding: 16px;
      gap: 24px;
      .reports-inputs {
        width: 100%;
        max-width: 950px;
        display: flex;
        gap: 16px;
        .reports-input-container {
          width: 100%;
          min-height: 42px;
          .reports-input-label {
            font-size: 14px;
            color: #000;
            margin-bottom: 4px;
          }
        }
        .custom-select-container {
          margin: 0;
        }
      }
      .button-container {
        button {
          max-height: 35px;
          font-size: 14px;
        }
      }
    }
  }
}
