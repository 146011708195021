.ruleViolationContainer {
  display: flex;
  gap: 10px;
  flex-direction: column;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
  .ruleViolationRow {
    display: flex;
    gap: 10px;
    .selectionSection {
      width: 95%;
      .right-panel {
        background: #f2f2f2;
        min-width: 300px;
        position: absolute;
        transform: translate(297px, 2px);
        border: 1px solid rgba(0, 0, 0, 0.15);
        height: 290px;
        border-radius: 0 0.25rem 0.25rem 0;
        padding-top: 20px;
        line-height: 15px;
        overflow: scroll;
        z-index: 99;
        /* Hide scrollbar for IE, Edge and Firefox */
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */

        &::-webkit-scrollbar {
          display: none;
        }
      }
      .ruleViolationDescription {
        margin-top: 5px;
        width: 100%;
        padding: 10px;
        border-radius: 0.25rem;
        border-color: #aaa;
        color: #444;
        &:disabled {
          cursor: not-allowed;
        }
      }
    }
    .controlSection {
      margin: 0;
      padding: 0;
      font-size: 25px;
      width: fit-content;
      .last-item {
        display: flex;
        flex-direction: column;
      }
      .remove {
        color: #ff5216;
        cursor: pointer;
      }
      .add {
        color: #2cda9b;
        cursor: pointer;
      }
    }
  }
}
